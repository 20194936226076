Drupal.behaviors.SDSearchGnavFormatterV1 = (function ($) {
  var $sdSearchIcon = $();
  var $body = $();
  var targetNode;
  var behavior = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      $sdSearchIcon = $('.js-sd-search-gnav-input-field', context);

      $body = $(context);
      targetNode = $body[0].documentElement;
      var observer = new MutationObserver(function (mutations) {
        var length = mutations.length;
        for (var i = 0; i < length; i++) {
          var mutation = mutations[i];
          if (mutation.target.className.includes('search-overlay-displayed')) {
            $body.find('.js-search-active-screen-wrapper').addClass('transition-active');
          }
        }
      });

      observer.observe(targetNode, {
        attributes: true,
        attributeFilter: ['class']
      });

      $sdSearchIcon
        .off('click touchstart')
        .on('click touchstart', '.js-gnav-search-button', function () {
          $body.toggleClass('active-utility-overlay', true);
        });

      $sdSearchIcon.on('click touchstart', '.js-search-close-icon-wrapper', function () {
        $body.toggleClass('active-utility-overlay', false);
      });
    }
  };

  return behavior;
})(jQuery);
